<template>
    <div class="blog">
        <el-timeline :reverse="true">
            <el-timeline-item v-for="(item, index) in list" :key="index" :timestamp="formatDate(item.date)"
                              placement="top">
                <el-card v-for="(article, artIndex) in item.articles" :key="index + '' + artIndex">
                        <span class="timeline-title" @click="goto(article)">
                            <template v-if="article.title">{{ article.title }}</template>
                            <template v-else>{{ article.name }}</template>
                        </span>
                    <p v-if="article.summary" class="timeline-summary">{{ article.summary }}</p>
                </el-card>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>

<script>

export default {
    name: 'Blog',
    data () {
        return {
            list: []
        }
    },
    methods: {
        formatDate (date) {
            function fill (num) {
                return num >= 10 ? num : '0' + num
            }

            if (typeof date === "string") {
                return date
            }

            return date.getFullYear() + '年' + fill(date.getMonth() + 1) + '月' + fill(date.getDate()) + '日'
        },
        goto (item) {
            console.log(item)
            this.$router.push('/blog/' + (item.folder ? item.folder + '/' : '') + item.name)
        },
        load () {
            this.utils.getJson(this.$store.state.api.blogList, {}).then(response => {
                this.list.splice(0, this.list.length)
                console.log(response)
                let lastDate = ''
                let lastItem = undefined
                for (const el of response) {
                    if (el.date === lastDate) {
                        lastItem.articles.push(el)
                    } else {
                        lastDate = el.date
                        lastItem = {
                            date: el.date,
                            articles: [el]
                        }
                        this.list.push(lastItem)
                    }
                }
            })
        }
    },
    mounted () {
        this.load()
    }
}
</script>

<style scoped>

.blog {
    max-width: 640px;
    margin: 0 auto;
    padding: 32px 0;
}

.timeline-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer;
}

.timeline-summary {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    color: #555555;
}

.el-card {
    margin: 12px 0;
}

.el-card:last-child {
    margin: 0;
}

.el-card__body > p:last-child {
    margin-bottom: 0;
}

</style>
